html {
	font-size: 10px;
	height: 100%;
}
* {
	box-sizing: border-box;
}
body {
	background-image: url(./bg1.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	min-height: 100%;
	display: grid;
	margin: 0;
	padding: 0;
	background-color: #363636;
	color: #ccc;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
a:link {
	color: rgb(51, 255, 255);
	text-decoration: none;
}
/* visited link */

a:visited {
	color: rgb(56, 216, 255);
}
/* mouse over link */

a:hover {
	color: #ccc;
}
/* selected link */

a:active {
	color: #800000;
}
p {
	font-size: 1.6rem;
}
small {
	font-size: 1.4rem;
}
.container {
	margin: 0 auto;
	max-width: 80%;
	padding: 40px;
	min-height: 100%;
	display: grid;
	background: rgb(54, 54, 54);
}
h1 {
	color: #fff;
}
.title {
	font-size: 4.4rem;
	text-align: center;
	margin-top: -12px;
}
.form {
	display: grid;
}
.label {
	font-size: 1.8rem;
	margin-bottom: 0.2rem;
	text-transform: uppercase;
}
.input {
	font-size: 1.6rem;
	padding: 0.5rem 2rem;
	line-height: 2.8rem;
	border-radius: 5px;
	margin-bottom: 1rem;
}
.button {
	background-color: rgba(0, 0, 0, 0.75);
	color: white;
	padding: 1rem 2rem;
	border: 1px solid rgba(56, 216, 255, 0.75);
	border-radius: 5px;
	font-size: 1.4rem;
	cursor: pointer;
	transition: background-color 250ms;
	margin-left: 10px;
	margin-right: -10px;
}
.button:hover {
	background-color: rgba(60, 255, 0, 0.85);
	border: 1px solid rgba(56, 255, 89, 0.75);
	color: #000;
}
.card-list {
	margin-top: 4rem;
}
.card {
	padding: 2rem 4rem;
	border-radius: 10px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
	margin-bottom: 2rem;
	background-color: rgb(45, 45, 45);
}
.card--title {
	margin-bottom: 0.5rem;
	font-size: 3.2rem;
}
.card--image {
	margin: 0 auto;
	display: block
}
.footer {
	left: 0;
	right: 0;
	bottom: 0;
	height: 60px;
	text-decoration: none;
	color: rgb(56, 216, 255);
	text-align: center;
	grid-row-start: 2;
	grid-row-end: 3;
	position: fixed;
	left: 0;
	bottom: 0;
	margin: 0 auto;
	background: rgb(54, 54, 54);
	width: 80%
}
.reactlogo {
	height: 20px;
	margin-top: 8px
}
.logo {
	height: 100px;
	margin-top: -25px
}
.logoalt {
	float: right;
	height: 100px;
	margin-top: -179px
}
.title {
	width: 80%;
	margin: 0 auto;
	margin-top: -10px;
	margin-bottom: 10px;
}
.footertext {
	margin-top: 9px;
}
.whatson {
	text-align: center;
	color: #ccc;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.watchone {
	max-width: 100%;
  margin-right: 10px;
  margin-left: 40px;
}
.watchtwo {
	max-width: 100%;
}
.watchthree {
	max-width: 100%;
}
.watchonecontent {
	text-align: center;
}
.watchonedesc {
	text-align: justify;
}
/* .grid-container {
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-gap: 20px;
} */

.grid-container {
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap: 50px;
	padding: 10px;
}
.grid-container>div {
	text-align: center;
	padding: 20px 0;
	font-size: 30px;
}
.nflogo {
  height:30px;
  margin-left:15px;
  margin-bottom:-3px
}
.nflogo:hover {
  transform: scale(1.5);
  color:red;
  height:30px;
  margin-left:15px;
  margin-bottom:-2px;
}
@media (min-width:1025px) {
	.form {
		grid-template-columns: auto 1fr auto;
		grid-gap: 1rem;
		align-items: center;
	}
	.input {
		margin-bottom: 0;
	}
}
@media (max-width:1024px) {
	.label {
		display: none;
	}
	.button {
		background-color: rgba(0, 0, 0, 0.75);
		color: white;
		padding: 1rem 2rem;
		border: 1px solid rgba(0, 0, 0, 0.75);
		border-radius: 10px;
		font-size: 5rem;
		cursor: pointer;
		transition: background-color 250ms;
		line-height: 7rem;
		margin-left: 10px;
		margin-right: -10px;
	}
	.container {
		margin: 0 auto;
		max-width: 100%;
		padding: 40px;
		min-height: 100%;
		display: grid;
		background: rgb(54, 54, 54);
	}
	.form {
		grid-template-columns: auto 1fr auto;
		grid-gap: 1rem;
		align-items: center;
	}
	.input {
		font-size: 5rem;
		line-height: 7rem;
		border-radius: 10px;
		margin-bottom: 0;
	}
	.card {
		padding: 2rem 4rem;
		border-radius: 10px;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
		margin-bottom: 2rem;
		background-color: rgb(17, 17, 17);
	}
	.card--image {
		width: 80%;
	}
	.card--title {
		margin-bottom: 0.5rem;
		font-size: 7rem;
		text-align: center;
	}
	small {
		font-size: 3rem;
	}
	.card--desc {
		font-size: 1.5em;
		text-align: justify;
	}
	.footer {
		position: fixed;
		left: 0;
		width: 100%;
		margin-top: -9px;
	}
	.footertext {
		font-size: 3em;
		background: none;
		margin-top: 15px;
	}
	.title {
		width: 100%;
		margin: 0 auto;
		margin-top: -5px;
		margin-bottom: 20px;
	}
	.whatson {
		font-size: 6em;
		text-align: center;
		color: #ccc;
		font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  .watchone {
    margin-left:0;
  }
	.grid-container {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 50px;
		padding: 10px;
		font-size: 3em;
	}
	.grid-container>div {
		text-align: center;
		padding: 20px 0;
		font-size: 30px;
	}
}